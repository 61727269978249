<template>
  <crud-form
    :titulo-pagina="tituloPagina"
    :is-editting="isEditting"
    :mensagem-pergunta-remocao="$t('padrao_deseja_remover_registro')"
    :mostra-botao-salvar-continuar="false"
    @deleteEvent="remover"
    @closeDialogEvent="closeDialog"
    @saveEvent="saveEventHandler"
  >
    <v-container class="py-0">
      <v-row>
        <v-col
          v-if="!idEmpresaCliente || idEmpresaCliente < 0"
          cols="12"
        >
          <empresa-cliente-auto-complete
            v-model="empresaClienteModel"
            :readonly="!!empresaClienteModelId || isEditting"
            :clearable="!isEditting"
            @click:clear="limpaCaixas()"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="objEditar.nome"
            :label="$t('FormaPagamentoForm.nome')"
            class="purple-input"
            prepend-icon="mdi-account-edit"
          />
        </v-col>

        <v-col
          cols="6"
        >
          <v-select
            v-model="objEditar.finalidade"
            :label="$t('FormaPagamentoForm.finalidade')"
            :items="finalidades"
            item-value="id"
            item-text="nome"
            required
            prepend-icon="mdi-format-list-bulleted-type"
          />
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <caixa-select
            v-model="objEditar.idCaixa"
            :id-empresa-cliente="empresaClienteModelCaixaSelect"
            required
            adiciona-valor-selecione
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-switch
            v-model="objEditar.permiteParcelamento"
            :label="$t('FormaPagamentoForm.permiteParcelamento')"
            class="purple-input"
            prepend-icon="mdi-notebook-multiple"
          />
        </v-col>


        <v-col
          cols="12"
          sm="6"
        >
          <v-switch
            v-model="objEditar.ativo"
            :label="$t('padrao_ativo')"
            class="purple-input"
            prepend-icon="mdi-notebook-multiple"
          />
        </v-col>

        <v-col
          v-show="objEditar.permiteParcelamento"
          cols="12"
          sm="6"
        >
          <v-text-field
            v-model="objEditar.porcentagemCadaPagamento"
            :label="$t('FormaPagamentoForm.porcentagemCadaPagamento')"
            class="purple-input"
            prepend-icon="mdi-ticket-percent-outline"
            type="number"
          />
        </v-col>
        <v-col
          v-show="objEditar.permiteParcelamento"
          cols="12"
          sm="6"
        >
          <v-text-field
            v-model="objEditar.valorCadaPagamento"
            :label="$t('FormaPagamentoForm.valorCadaPagamento')"
            class="purple-input"
            prepend-icon="mdi-credit-card-outline"
            type="number"
          />
        </v-col>
        <v-col
          v-show="objEditar.permiteParcelamento"
          cols="12"
          sm="6"
        >
          <v-text-field
            v-model="objEditar.taxaJurosParcelado"
            :label="$t('FormaPagamentoForm.taxaJurosParcelado')"
            class="purple-input"
            prepend-icon="mdi-label-percent-outline"
            type="number"
          />
        </v-col>
      </v-row>
      <v-row v-show="objEditar.permiteParcelamento">
        <v-col
          
          cols="12"
          sm="6"
        >
          <v-text-field
            v-model="objEditar.diaVencimentoPrestacao"
            :label="$t('FormaPagamentoForm.diaVencimentoPrestacao')"
            class="purple-input"
            prepend-icon="mdi-calendar-today"
            type="number"
          />
        </v-col>
        
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            v-model="objEditar.diasFecharPrestacao"
            :label="$t('FormaPagamentoForm.diasFecharPrestacao')"
            class="purple-input"
            prepend-icon="mdi-calendar-today"
            type="number"
          />
        </v-col>
      </v-row>
    </v-container>
  </crud-form>
</template>

<script>

  import Servico from '../services/FormaPagamentoService'
  import { mapState } from 'vuex'
  import DateUtils from '../utils/DateUtils'
  import EmpresaClienteAutoComplete from '@/views/EmpresaClienteAutoComplete'
  import EmpresaClientesService from '@/services/EmpresaClientesService'
  import CaixaSelect from '../components/CaixaSelect'

  export default {
    components: {
      EmpresaClienteAutoComplete,
      CaixaSelect
    },
    data: () => ({
      menuDataCompra: false,
      empresaClienteModel: null,
      empresaClienteModelId: null,
      caixaModel: null,
      objPadrao: {
        nome: '',
        porcentagemCadaPagamento: null,
        valorCadaPagamento: null,
        ativo: true,
        finalidade: null,
        diasFecharPrestacao: null,
        diaVencimentoPrestacao: null,
        permiteParcelamento: false,
        taxaJurosParcelado: null,
        idEmpresaCliente: null,
        idCaixa: null
      },
      objEditar: {
        nome: '',
        porcentagemCadaPagamento: null,
        valorCadaPagamento: null,
        ativo: true,
        finalidade: null,
        diasFecharPrestacao: null,
        diaVencimentoPrestacao: null,
        permiteParcelamento: false,
        taxaJurosParcelado: null,
        idEmpresaCliente: null,
        idCaixa: null
      },
      isEditting: true
    }),
    computed: {
      ...mapState(['idEmpresaCliente']),
      tituloPagina () {
        return this.$i18n.t('FormaPagamentoForm.titulo')
      },
      empresaClienteModelCaixaSelect() {
        const retorno = this.empresaClienteModelId || this.idEmpresaCliente
        return retorno
      },
      finalidades() {
        const retorno = [
          {
            id: null,
            nome: this.$i18n.t('padrao_selecione')
          },
          {
            id: 1,
            nome: this.$i18n.t('FormaPagamentoForm.finalidade_1')
          },
          {
            id: 2,
            nome: this.$i18n.t('FormaPagamentoForm.finalidade_2')
          },
          {
            id: 3,
            nome: this.$i18n.t('FormaPagamentoForm.finalidade_3')
          }
        ]
        return retorno
      }
    },
    watch: {
      caixaModel (val) {
        if (val === null || !val) {
          this.objEditar.idCaixa = null;
        } else {
          this.objEditar.idCaixa = val;
        }
      },

      autoCompleteFornecedorCampoBusca (val) {
        if (!val) {
          return
        }
        if (val.length < 3) {
          return
        }
        this.buscaFornecedor(val)
      },
      empresaClienteModel (val) {
        if (val === null || !val) {
          this.objEditar.EmpresaCliente = null
          this.objEditar.idEmpresaCliente = null
          this.empresaClienteModelId = null
        } else {
          this.objEditar.EmpresaCliente = val
          this.objEditar.idEmpresaCliente = val.id
          this.empresaClienteModelId = val.id
        }
      }

    },
    created () {
      this.$root.$emit('alteraMostraPesquisa', false)

      let id = this.$route.params.id
      if (id) {
        this.editar(id)
      } else {
        this.isEditting = false
        this.objEditar = Object.assign({}, this.objPadrao)

        if (this.idEmpresaCliente && this.idEmpresaCliente >= 0) {
          EmpresaClientesService.findById(this.idEmpresaCliente).then((res) => {
            this.objEditar.EmpresaCliente = res.data
            this.empresaClienteModel = res.data
            this.objEditar.idEmpresaCliente = res.data.id
          })
        }
      }
    },
    methods: {
      parseDate (date) {
        return DateUtils.parseDate(date)
      },
      limpaFornecedores () {
        this.autoCompleteFornecedorItens = []
        this.autoCompleteFornecedorModel = null
      },
      saveContinueEvent () {
        if (this.idEmpresaCliente) {
          this.objEditar.idEmpresaCliente = this.idEmpresaCliente
        }
        this.inserir(this.objEditar, true)
      },
      saveEventHandler () {
        if (this.idEmpresaCliente && this.idEmpresaCliente >= 0) {
          this.objEditar.idEmpresaCliente = this.idEmpresaCliente
        }

        if (this.caixaModel) {
          this.objEditar.idCaixa = this.caixaModel
        }
        if (this.isEditting) {
          this.atualizar(this.objEditar)
        } else {
          this.inserir(this.objEditar)
        }
      },
      atualizar (registro) {
        try {

          Servico.update(registro).then((res) => {
            if (!res) {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: 'Deu algum erro,\nResposta do Servidor: null '
              })
            } else if (res.error) {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: '' + res.error
              })
            } else {
              this.$root.$emit('mostra_dialogo_info', {
                dialogoTitulo: this.$t('padrao_sucesso'),
                dialogoDescricao: this.$t('padrao_registro_salvo_sucesso')
              })
              this.closeDialog()
            }
          })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao:
              'Não foi possível concluir a atualização, tente mais tarde\n' +
              error
          })
        }
      },
      closeDialog () {
        this.$router.push('/formasPagamento')
      },
      remover () {
        const registro = this.objEditar
        try {
          Servico.delete(registro)
            .then(response => {
              this.$root.$emit('mostra_dialogo_info', {
                dialogoTitulo: this.$t('padrao_sucesso'),
                dialogoDescricao: this.$t('padrao_registro_removido_sucesso')
              })
              this.closeDialog()
            })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao:
              this.$t('padrao_ocorreu_um_erro_ultima_operacao') +
              ':\n ->' +
              error
          })
        }
      },
      inserir (registro, manterNestaTela) {
        try {
          Servico.insert(registro).then(response => {
            if (!response) {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: 'Deu algum erro,\nResposta do Servidor: null '
              })
            } else if (response.error) {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: '' + response.error
              })
            } else {
              this.$root.$emit('mostra_dialogo_info', {
                dialogoTitulo: this.$t('padrao_sucesso'),
                dialogoDescricao: this.$t('padrao_registro_salvo_sucesso')
              })
              if (manterNestaTela) {
                this.editar(response.data.id)
              } else {
                this.closeDialog()
              }
            }
          })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao: 'Ocorreu um erro ao inserir um Despera:\n ->' + error
          })
        }
      },
      editar (id) {
        Servico.findById({ id })
          .then((registro) => {
            this.objEditar = registro.data
            this.isEditting = true

            this.empresaClienteModel = this.objEditar.EmpresaCliente
            this.empresaClienteModelId = this.objEditar.idEmpresaCliente
          })
          .catch(() => {
            this.isEditting = false
          })
      }
    }
  }
</script>
